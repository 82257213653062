import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import Logo from "../images/icons/eo-discover-reverse.svg";

const style = {
  container: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "80%",
    paddingLeft: "10%",
    paddingRight: "10%",
    backgroundColor: "#000",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },
  mainHeader: {
    color: "white",
    textDecoration: "underline",
    fontWight: "bold",
    paddingTop: "5%",
    paddingBottom: "7.5%",
    fontFamily: "Sans-serif",
  },
  header: {
    color: "blue",
    fontWight: "bold",
    paddingTop: "5%",
    paddingBottom: "7.5%",
    fontFamily: "Sans-serif",
  },
  paragraph: {
    color: "white",
    fontFamily: "Sans-serif",
  },
};

const PrivacyPolicy = () => {
  // Translation component
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.backgroundColor = "#000";
  }, []);

  return (
    <>
      <Logo
        style={{
          width: "20rem",
          height: "5rem",
          position: "absolute",
          zIndex: "999",
        }}
      />
      <div style={style.container}>
        <h1 style={style.mainHeader}>{t("header.policy")}</h1>
        <div style={style.section}>
          <p style={style.paragraph}>
            {t("policy.one.partOne")}
            {` (`}
            {t("policy.one.partOneAndHalf")}
            {` \"`}
            <b style={style.paragraph}>{t("policy.one.boldOne")}</b>
            {`\") `}
            {t("policy.one.partTwo")}
            {`  (\"`}
            <b style={style.paragraph}>{t("policy.one.boldTwo")}</b>
            {'", “'}
            <b style={style.paragraph}>{t("policy.one.boldThree")}</b>
            {"”, “"}
            <b style={style.paragraph}>{t("policy.one.boldFour")}</b>
            {"”, “"}
            <b style={style.paragraph}>{t("policy.one.boldFive")}</b>
            {`) ”`}
            {t("policy.one.partThree")}
            {` (`}
            {t("policy.one.partThreeAndHalf")}
            {` “`}
            <b style={style.paragraph}>{t("policy.one.boldSix")}</b>
            {`”), `}
            {t("policy.one.partFour")}
            {` (`}
            {t("policy.one.partFourAndHalf")}
            {` “`}
            <b style={style.paragraph}>{t("policy.one.boldSeven")}</b>
            {`”). `}
            {t("policy.one.partFive")}
            {` (`}
            {t("policy.one.partFiveAndHalf")}
            {` “`}
            <b style={style.paragraph}>{t("policy.one.boldEight")}</b>
            {"”)."}
          </p>
          <br />
          <br />
          <br />
          <br />
          <p style={style.paragraph}>{t("policy.two")}</p>
          {`.`}
          <br />
          <p style={style.paragraph}>{t("policy.three")}</p>
          {`.`}
          <br />
          <p style={style.paragraph}>{t("policy.four")}</p>
          {`.`}
        </div>
        <h1 style={style.header}>{t("header.data")}</h1>
        <div style={style.section}>
          <b style={style.paragraph}>{t("data.one")}</b>
          <br />
          <p style={style.paragraph}>{t("data.two")}</p>
          {`. `}
          <br />
          <p style={style.paragraph}>
            {t("data.three.partOne")}
            {` “`}
            <b style={style.paragraph}>{t("data.three.boldOne")}</b>
            {`”.`}
          </p>
          <br />
          <br />
          <br />
          <br />
          <b style={style.paragraph}>{t("data.four")}</b>
          <br />
          <p style={style.paragraph}>
            {t("data.five.partOne")}
            {` (\"`}
            <b style={style.paragraph}>{t("data.five.boldOne")}</b>
            {`\"). `}
            {t("data.five.partTwo")}
            {`.`}
          </p>
          <br />
          <br />
          <br />
          <br />
          <b style={style.paragraph}>{t("data.six")}</b>
          <br />
          <p style={style.paragraph}>
            {t("data.seven.partOne")}
            {`(“`}
            <b style={style.paragraph}>{t("data.seven.boldOne")}</b>
            {`”), `}
            {t("data.seven.partTwo")}
            {`.`}
          </p>
          <br />
          <br />
          <br />
          <br />
          <b style={style.paragraph}>{t("data.eight")}</b>
          <br />
          <p style={style.paragraph}>
            {t("data.nine.partOne")}
            {` (`}
            {t("data.nine.partTwo")}
            {`), `}
            {t("data.nine.partThree")}
            {` “`}
            <b style={style.paragraph}>{t("data.nine.boldOne")}</b>
            {`”. `}
            {t("data.nine.partFour")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("data.ten")}
            {`.`}
          </p>
          <br />
          <br />
          <br />
          <br />
          <b style={style.paragraph}>{t("data.eleven")}</b>
          <br />
          <p style={style.paragraph}>
            {t("data.twelve.one")}
            {` (`}
            {t("data.twelve.oneAndHalf")}
            {`), `}
            {t("data.twelve.two")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("data.thirteen.partOne")}
            {` “`}
            <b style={style.paragraph}>{t("data.thirteen.boldOne")}</b>
            {`”.`}
          </p>
          <br />
          <br />
          <br />
          <br />
          <b style={style.paragraph}>{t("data.fourteen")}</b>
          <br />
          <p style={style.paragraph}>{t("data.fifteen")}</p>
          {`.`}
          <br />
          <p style={style.paragraph}>
            {t("data.sixteen.partOne")}
            {` “`}
            <b style={style.paragraph}>{t("data.sixteen.boldOne")}</b>
            {`”.`}
          </p>
          <br />
          <br />
          <br />
          <br />
          <b style={style.paragraph}>{t("data.seventeen")}</b>
          <br />
          <p style={style.paragraph}>
            {t("data.eighteen.partOne")}
            {`. (\"`}
            <b style={style.paragraph}>{t("data.eighteen.boldOne")}</b>
            {`\").`}
          </p>
          <br />
          <br />
          <br />
          <br />
          <p style={style.paragraph}>
            {t("data.nineteen.one")}
            {` - `}
            {t("data.nineteen.two")}
            {` - `}
            {t("data.nineteen.three")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>{t("data.twenty")}</p>
          <br />
        </div>
        <h1 style={style.header}>{t("header.personalData")}</h1>
        <div style={style.section}>
          <b style={style.paragraph}>{t("personalData.one")}</b>
          <br />
          <p style={style.paragraph}>
            {t("personalData.two")}
            {`.`}
          </p>
          <br />
          <br />
          <br />
          <br />
          <b style={style.paragraph}>{t("personalData.three")}</b>
          <br />
          <p style={style.paragraph}>
            {t("personalData.four")}
            {`.`}
          </p>
          <br />
          <br />
          <br />
          <br />
          <b style={style.paragraph}>{t("personalData.five")}</b>
          <br />
          <p style={style.paragraph}>
            {t("personalData.six")}
            {`.`}
          </p>
          <br />
          <br />
          <br />
          <br />
          <b style={style.paragraph}>{t("personalData.seven")}</b>
          <br />
          <p style={style.paragraph}>
            {t("personalData.eight.partOne")}
            {`: `}
            <a href="mailto:Marketing@asterra.io">
              {t("personalData.eight.mailToOne")}
            </a>
            {`, `}
            {t("personalData.eight.partTwo")}
            {`.`}
          </p>
          <br />
          <br />
          <br />
          <br />
          <b style={style.paragraph}>{t("personalData.nine")}</b>
          <br />
          <p style={style.paragraph}>
            {t("personalData.ten")}
            {`.`}
          </p>
          <br />
        </div>
        <h1 style={style.header}>{t("header.share")}</h1>
        <div style={style.section}>
          <p style={style.paragraph}>
            {t("share.one.partOne")}
            {`; `}
            {t("share.one.partTwo")}
            {`:`}
          </p>
          <br />
          <br />
          <br />
          <br />
          <b style={style.paragraph}>{t("share.two")}</b>
          <br />
          <p style={style.paragraph}>
            {t("share.three")}
            {`.`}
          </p>
          <br />
          <br />
          <b style={style.paragraph}>{t("share.four")}</b>
          <br />
          <p style={style.paragraph}>
            {t("share.five")}
            {`:`}
          </p>
          <br />
          <br />
          <p style={style.paragraph}>
            {t("share.six.partOne")}
            {`: `}
            <a href="https://www.giscloud.com/privacy-policy/">
              {t("share.six.linkOne")}
            </a>
          </p>
          <br />
          <p style={style.paragraph}>
            {t("share.seven")}
            {`.`}
          </p>
          <br />
          <br />
          <b style={style.paragraph}>{t("share.eight")}</b>
          <br />
          <p style={style.paragraph}>
            {t("share.nine.partOne")}
            {` (`}
            {t("share.nine.partTwo")}
            {`), `}
            {t("share.nine.partThree")}
            {`.`}
          </p>
          <br />
          <br />
          <b style={style.paragraph}>{t("share.ten")}</b>
          <br />
          <p style={style.paragraph}>
            {t("share.eleven")}
            {`.`}
          </p>
          <br />
          <br />
          <b style={style.paragraph}>{t("share.twelve")}</b>
          <br />
          <p style={style.paragraph}>
            {t("share.thirteen.partOne")}
            {` (`}
            {t("share.thirteen.partTwo")}
            {`), `}
            {t("share.thirteen.partThree")}
            {`.`}
          </p>
          <br />
          <br />
        </div>
        <h1 style={style.header}>{t("header.cookies")}</h1>
        <div style={style.section}>
          <p style={style.paragraph}>
            {t("cookies.one")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("cookies.two")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("cookies.three")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("cookies.four.partOne")}
            {` `}
            <a href="https://policies.google.com/technologies/cookies#types-of-cookies">
              {t("cookies.four.linkOne")}
            </a>
            {`. `}
            {t("cookies.four.partTwo")}
            {` `}
            <a href="https://policies.google.com/technologies/cookies#managing-cookies">
              {t("cookies.four.linkOne")}
            </a>
            {`.  `}
            {t("cookies.four.partThree")}
            {` `}
            <a href="https://help.hotjar.com/hc/en-us/articles/6952777582999-Cookies-Set-by-the-Hotjar-Tracking-Code">
              {t("cookies.four.linkOne")}
            </a>
            {`.`}
          </p>
        </div>
        <h1 style={style.header}>{t("header.security")}</h1>
        <div style={style.section}>
          <b style={style.paragraph}>{t("security.one")}</b>
          <br />
          <p style={style.paragraph}>
            {t("security.two")}
            {`.`}
          </p>
          <br />
          <b style={style.paragraph}>{t("security.three")}</b>
          <br />
          <p style={style.paragraph}>
            {t("security.four")}
            {`.`}
          </p>
          <br />
        </div>
        <h1 style={style.header}>{t("header.europe")}</h1>
        <div style={style.section}>
          <p style={style.paragraph}>
            <b style={style.paragraph}>{t("europe.one.boldOne")}</b>
            {` `}
            {t("europe.one.partOne")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("europe.two.partOne")}
            {`: `}
            <a href="mailto:Marketing@asterra.io">
              {t("europe.two.mailToOne")}
            </a>
          </p>
          <br />
          <br />
          <br />
          <b style={style.paragraph}>{t("europe.three")}</b>
          <br />
          <p style={style.paragraph}>
            {t("europe.four")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("europe.five")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("europe.six")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("europe.seven")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("europe.eight")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("europe.nine.partOne")}
            {` (`}
            {t("europe.nine.partTwo")}
            {`).`}
          </p>
          <br />
          <p style={style.paragraph}>{t("europe.ten")}</p>
          <br />
          <br />
          <b style={style.paragraph}>{t("europe.eleven")}</b>
          <br />
          <p style={style.paragraph}>
            {t("europe.twelve")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("europe.thirteen")}
            {`.`}
          </p>
          <br />
          <b style={style.paragraph}>{t("europe.fourteen")}</b>
          <br />
          <p style={style.paragraph}>
            {t("europe.fifteen")}
            {`:`}
          </p>
          <br />
          <p style={style.paragraph}>
            <b style={style.paragraph}>{t("europe.sixteen.boldOne")}</b>
            {`: `}
            {t("europe.sixteen.partOne")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            <b style={style.paragraph}>{t("europe.seventeen.boldOne")}</b>
            {`: `}
            {t("europe.seventeen.partOne")}
            {`. `}
          </p>
          <br />
          <p style={style.paragraph}>
            <b style={style.paragraph}>{t("europe.eighteen.boldOne")}</b>
            {`: `}
            {t("europe.eighteen.partOne")}
            {`. `}
          </p>
          <br />
          <p style={style.paragraph}>
            <b style={style.paragraph}>{t("europe.nineteen.boldOne")}</b>
            {`: `}
            {t("europe.nineteen.partOne")}
            {` ‘`}
            {t("europe.nineteen.partTwo")}
            {`’ `}
            {t("europe.nineteen.partThree")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            <b style={style.paragraph}>{t("europe.twenty.boldOne")}</b>
            {`: `}
            {t("europe.twenty.partOne")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            <b style={style.paragraph}>{t("europe.twentyOne.boldOne")}</b>
            {`: `}
            {t("europe.twentyOne.partOne")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            <b style={style.paragraph}>{t("europe.twentyTwo.boldOne")}</b>
            {`: `}
            {t("europe.twentyTwo.partOne")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("europe.twentyThree.partOne")}
            {`: `}
            <a href="mailto:Marketing@asterra.io">
              {t("europe.twentyThree.mailToOne")}
            </a>
          </p>
          <br />
          <p style={style.paragraph}>
            {t("europe.twentyFour")}
            {`.`}
          </p>
          <br />
          <b style={style.paragraph}>{t("europe.twentyFive")}</b>
          <br />
          <p style={style.paragraph}>
            {t("europe.twentySix.partOne")}
            {` `}
            <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">
              {t("europe.twentySix.linkOne")}
            </a>
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("europe.twentySeven.partOne")}
            {` (`}
            {t("europe.twentySeven.partTwo")}
            {`) `}
            {t("europe.twentySeven.partThree")}
            {` `}
            <a href="https://ico.org.uk/make-a-complaint/">
              {t("europe.twentySeven.linkOne")}
            </a>
          </p>
        </div>
        <h1 style={style.header}>{t("header.minors")}</h1>
        <div style={style.section}>
          <b style={style.paragraph}>{t("minors.one")}</b>
          <br />
          <p style={style.paragraph}>
            {t("minors.two")}
            {`.`}
          </p>
          <br />
        </div>
        <h1 style={style.header}>{t("header.changes")}</h1>
        <div style={style.section}>
          <p style={style.paragraph}>
            {t("changes.one")}
            {`.`}
          </p>
          <br />
          <p style={style.paragraph}>
            {t("changes.lastUpdated")}
            {`.`}
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["privacy"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
